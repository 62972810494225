import { WorksCategoriesData } from './types';

export const worksCategories: WorksCategoriesData[] = [
  {
    id: 0,
    title: 'All',
  },
  {
    id: 1,
    title: 'HealthCare',
  },
  {
    id: 2,
    title: 'Medical',
  },
  {
    id: 3,
    title: 'Services',
  },
  {
    id: 4,
    title: 'Beauty Sphere',
  },
];

export const blogCategories: WorksCategoriesData[] = [
  {
    id: 0,
    title: 'All',
  },
  {
    id: 1,
    title: 'HealthCare',
  },
  {
    id: 2,
    title: 'Medical',
  },
  {
    id: 3,
    title: 'Services',
  },
];

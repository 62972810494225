import * as React from 'react';
import Categories from '../Categories/Categories';
import './Navigation.scss';
import { WorksCategoriesData } from '../../Works/types';

interface NavigationProps {
  title: string;
  change: number;
  setChange: (arg: number) => void;
  categories: WorksCategoriesData[];
}

const Navigation = ({ title, change, setChange, categories }: NavigationProps) => {
  return (
    <div className="navigation">
      <h1>{title}</h1>
      <Categories selectedCategory={change} setSelectedCategory={setChange} categories={categories} />
    </div>
  );
};

export default Navigation;

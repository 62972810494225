import * as React from 'react';
import PageLayout from '../../components/_Shared/PageLayout/PageLayout';
import '../../../node_modules/normalize.css/normalize.css';
import '../../globalStyles/_fonts.scss';
import '../../globalStyles/_styles.scss';
import BlogContent from '../../components/Blog/Blog';
import { blogTitle } from '../../seoData/titles';
import { blogDescription } from '../../seoData/descriptions';
import { graphql } from 'gatsby';
import { BlogPageData } from '../../components/Blog/types';

export const query = graphql`
  query MyBlog {
    allSanityBlogArticle {
      nodes {
        publicationDate
        id
        title
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
        categories {
          sanityId
          title
        }
        summary
        _rawContent
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const Blog = (props: BlogPageData) => {
  const blogContentData = props.data.allSanityBlogArticle.nodes;
  const { allSanityFooter } = props.data;

  return (
    <PageLayout footerData={allSanityFooter} title={blogTitle} description={blogDescription}>
      <BlogContent data={blogContentData} />
    </PageLayout>
  );
};

export default Blog;

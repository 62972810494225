import React from 'react';
import '../../Works/Works.scss';
import { BTN_STYLES, Button } from '../Button/Button';
import './Categories.scss';
import { CategoriesProps } from './types';

const Categories = ({ categories, selectedCategory, setSelectedCategory }: CategoriesProps) => {
  const categoryHandler = (id: number) => {
    setSelectedCategory(id);
    localStorage.setItem('category', `${id}`);
  };

  return (
    <div className="head_links">
      {categories.map(({ title, id }, index: number) => {
        const isSelectedCategory = id === selectedCategory;

        return (
          <Button
            key={index}
            title={title}
            className={
              isSelectedCategory
                ? `selected_category ${BTN_STYLES.BTN_SECONDARY_GRAY_SMALL}`
                : BTN_STYLES.BTN_SECONDARY_GRAY_SMALL
            }
            onClick={() => categoryHandler(id)}
          />
        );
      })}
    </div>
  );
};

export default Categories;

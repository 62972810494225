import * as React from 'react';
import './Blog.scss';
import { BlogCard, CARD_STYLES } from '../_Shared/BlogCard/BlogCard';
import { useEffect, useState } from 'react';
import Navigation from '../_Shared/Navigation/Navigation';
import BreadCrumbs from '../_Shared/Breadcrumbs/Breadcrumbs';
import { BlogCardData, BlogContentData } from './types';
import { countArticleReadingTime } from '../../utils/utilsReadingTime';
import { blogCategories } from '../Works/defaultData';

const BlogContent = ({ data }: BlogContentData) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [blogList, setBlogList] = useState(data);
  const filterByCategory = () => {
    const filtered = data.filter((card) => card.categories[0].sanityId === selectedCategoryId);

    return setBlogList(() => (selectedCategoryId === 0 ? data : filtered));
  };

  blogList.sort((date1, date2) => {
    const latestDate = new Date(date2.publicationDate).getTime();
    const oldestDate = new Date(date1.publicationDate).getTime();

    return latestDate - oldestDate;
  });

  useEffect(() => {
    filterByCategory();
    setSelectedCategoryId(Number(localStorage.getItem('category')));
  }, [selectedCategoryId]);

  return (
    <section className="blog">
      <div className="blog__breadCrumbs">
        <BreadCrumbs isDark />
      </div>
      <div className="blog__container">
        <Navigation
          categories={blogCategories}
          title="Blog"
          change={selectedCategoryId}
          setChange={setSelectedCategoryId}
        />
        <div className="blog__boxes">
          {blogList.map((blogCardData: BlogCardData, index: number) => {
            const {
              title,
              summary: description,
              slug: { current },
              image: {
                asset: { url },
              },
              categories,
              id,
              _rawContent: articleContent,
            } = blogCardData;
            const category = categories[0].title;
            const arrayOfSymbols: number[] = [];

            countArticleReadingTime(articleContent, arrayOfSymbols);
            const countOfSymbols = arrayOfSymbols.reduce((acc, element) => acc + element, 0);
            const readingTime = +(countOfSymbols / 1200).toFixed(0);

            return (
              <BlogCard
                className={!index ? CARD_STYLES.CARD_STYLES_LARGE : CARD_STYLES.CARD_STYLES_MEDIUM}
                title={CARD_STYLES.CARD_STYLES_LARGE && title}
                key={index}
                image={url}
                description={!index ? description : title}
                time={readingTime}
                categories={category}
                slug={current}
                articleId={id}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogContent;
